<template>
  <ErrorPage :link="link" :buttonText="buttonText">
    <v-container class="pb-0">
      <v-row justify="center" class="error-code pt-4">
        <v-col cols="12" class="pa-0">
          {{ title }}
        </v-col>
      </v-row>
      <v-row class="no-gutters" justify="space-around">
        <v-col cols="auto">
          <v-row no-gutters justify="center">
            <p class="mb-2 error-text">{{ description }}</p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </ErrorPage>
</template>

<script>
import ErrorPage from '@/modules/exception/ErrorPage';

export default {
  name: 'ErrorComponent',
  components: { ErrorPage },
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('exception.wrong_url');
      },
    },
    description: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: function() {
        return this.$t('exception.go_to_homepage');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.error-code {
  text-align: center;
  color: #000;
  @include font-size(36, 150, 500);
}

.error-code-mobile {
  font-size: 36px;
}

.error-text {
  text-align: center;
  @include font-size(18, 150);
  color: #000;
}
</style>
